// Note: Requires flexslider 2, buddy.
jQuery(document).ready(function($) {
	$('.three-by-slider-wrapper .all-slides').slick({
		adaptiveHeight: true,
		dots: true,
		nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-angle-right"></i>Next Slide</button>',
		prevArrow: '<button type="button" class="slick-prev"><i class="fa-solid fa-angle-left"></i>Previous Slide</button>',
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [{

			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			}
	  
		}, 
		{
	  
			breakpoint: 768,
			settings: {
				arrows: false,
				slidesToShow: 2,
				slidesToScroll: 2,
			}
	  
		},
		{
	  
			breakpoint: 600,
			settings: {
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1,
			}
	  
		}]
	});


	$('.three-by-slider-wrapper .lb-close').click(function(){
		$.fancybox.close();
	});
}); /* end of as page load scripts */